<template>
<div role="tab" class="mb-5">
  <b-container fluid>
    <b-row>
      <b-col>
        <strong>{{policy.policy.name}}</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
          <policy-initiative-comparison :policy="policy" />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
// import { BIconArrowsCollapse, BIconArrowsExpand } from 'bootstrap-vue'
// import NewLines from '@/components/NewLines.vue'
import PolicyInitiativeComparison from '@/components/PolicyInitiativeDetailsComparison'
export default {
  name: 'ImpactpolicyAccordionComparison',
  props: ['policy'],
  components: {
    PolicyInitiativeComparison
  },
  computed: {
  },
  created: async function () {
  },
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
