<template>
<div>
  <div>
    <b-alert show>
      <b-table-simple hover>
        <b-tbody>
          <template v-for="field in schema.fields">
              <b-tr v-if="field.type === 'number' && !field.skip" :key="field.name">
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative1">
                    <span v-if="initiative1.policydata[field.name]">
                      <span v-if="field.formatter">{{field.formatter(initiative1.policydata)}}</span>
                      <span v-else>{{initiative1.policydata[field.name]}}</span>
                    </span>
                    <span v-else>No data</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
                <b-th class="text-center" style="width: 33.33%">{{  field.label }}</b-th>
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative2">
                    <span v-if="initiative2.policydata[field.name]">
                      <span v-if="field.formatter">{{field.formatter(initiative2.policydata)}}</span>
                      <span v-else>{{initiative2.policydata[field.name]}}</span>
                    </span>
                    <span v-else>No data</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
              </b-tr>
              <b-tr v-if="field.type === 'boolean' && !field.skip" :key="field.name">
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative1">
                    <span v-if="initiative1.policydata[field.name] === 'true'">Yes</span>
                    <span v-else>No</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
                <b-th class="text-center" style="width: 33.33%">{{  field.label }}</b-th>
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative2">
                    <span v-if="initiative2.policydata[field.name] === 'true'">Yes</span>
                    <span v-else>No</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
              </b-tr>
              <b-tr v-if="field.type === 'select' && !field.skip" :key="field.name">
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative1">
                    <span v-if="initiative1.policydata[field.name]">
                      <span v-if="field.formatter">{{field.formatter(initiative1.policydata)}}</span>
                      <span v-else>{{initiative1.policydata[field.name]}}</span>
                    </span>
                    <span v-else>No data</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
                <b-th class="text-center" style="width: 33.33%">{{  field.label }}</b-th>
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative2">
                    <span v-if="initiative2.policydata[field.name]">
                      <span v-if="field.formatter">{{field.formatter(initiative2.policydata)}}</span>
                      <span v-else>{{initiative2.policydata[field.name]}}</span>
                    </span>
                    <span v-else>No data</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
              </b-tr>
              <b-tr v-if="field.type === 'checkbox' && !field.skip" :key="field.name">
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative1">
                    <span v-if="initiative1.policydata[field.name]">
                      <span v-if="initiative1.policydata[field.name].length > 0">
                        <span v-for="item in initiative1.policydata[field.name]" :key="`i1${field.name}${item}`">{{item}}, </span>
                      </span>
                      <span v-else>No data</span>
                    </span>
                    <span v-else>No data</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
                <b-th class="text-center" style="width: 33.33%">{{  field.label }}</b-th>
                <b-td class="text-center" style="width: 33.33%">
                  <span v-if="initiative2">
                    <span v-if="initiative2.policydata[field.name]">
                      <span v-if="initiative2.policydata[field.name].length > 0">
                        <span v-for="item in initiative2.policydata[field.name]" :key="`i2${field.name}${item}`">{{item}}, </span>
                      </span>
                      <span v-else>No data</span>
                    </span>
                    <span v-else>No data</span>
                  </span>
                  <span v-else>No data</span>
                </b-td>
              </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-alert>
  </div>
</div>
</template>

<script>
import translators from '@/libs/translators'
import policysets from '@/libs/policysets'

export default {
  name: 'PolicyInitiativeDetailsComparison',
  props: ['policy'],
  created: async function () {
    if (this.policy.initiatives[0]) {
      this.initiative1 = this.policy.initiatives[0]
    }
    if (this.policy.initiatives[1]) {
      this.initiative2 = this.policy.initiatives[1]
    }
  },
  computed: {
    schema () {
      // find record from policysets based on item.impactpolicy_id
      const record = policysets.find(item => item.id === this.policy.policy.id)
      return record
    }
  },
  data () {
    const data = {
      ...translators,
      initiative1: false,
      initiative2: false
    }
    return data
  }
}
</script>

<style scoped>

</style>
