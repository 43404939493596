<template>
<div class="my-4">
  <b-container fluid v-if="!reloading">
    <b-row v-if="initiatives.length > 0">
      <b-col>
        <b-card no-body>
          <b-tabs pills card vertical>
            <div v-for="item in initiativesByAreaAll" :key="'area' + item.area.id">
              <b-tab :title="item.area">
                <b-card-text>
                  <div class="accordion" role="tablist">
                    <b-container fluid>
                      <b-row class="mb-4">
                        <b-col>
                          <b-form>
                            <b-form-select v-model="countrySelected1" :options="countryOptions1" size="lg" style="font-weight: 900"></b-form-select>
                          </b-form>
                        </b-col>
                        <b-col>
                          <b-form>
                            <b-form-select v-model="countrySelected2" :options="countryOptions2" size="lg" style="font-weight: 900"></b-form-select>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-container>
                    <div v-for="policy in item.policies" :key="'policy'+ policy.policy.id">
                      <impactpolicy-accordion-comparison :policy="policy" />
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
            </div>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ImpactpolicyAccordionComparison from '@/components/ImpactpolicyAccordionComparison'

export default {
  name: 'CountryPolicies',
  components: {
    ImpactpolicyAccordionComparison
  },
  computed: {
    countryOptions1: function () {
      const all = this.$store.state.initiativesPolicies
      let results = all.map(x => x.country)
      results = _.uniq(results)
      _.pull(results, this.countrySelected2)
      results.sort()
      return results
    },
    countryOptions2: function () {
      const all = this.$store.state.initiativesPolicies
      let results = all.map(x => x.country)
      results = _.uniq(results)
      _.pull(results, this.countrySelected1)
      results.sort()
      return results
    },
    initiatives: function () {
      const country1 = this.countrySelected1
      const country2 = this.countrySelected2
      const results = []
      const all = this.$store.state.initiativesPolicies
      all.forEach(x => {
        if (x.country === country1 || x.country === country2) {
          results.push(x)
        }
      })
      return results
    },
    initiativesByAreaAll: function () {
      const c1 = this.countrySelected1
      const c2 = this.countrySelected2
      const all = []
      const allInitiatives = this.$store.state.initiativesPolicies
      allInitiatives.forEach(x => {
        if (x.country === c1 || x.country === c2) {
          all.push(x)
        }
      })
      const results = []
      const policies = this.policies
      this.areas.forEach(area => {
        let row = {
          area: area.name,
          policies: []
        }
        policies.forEach(policy => {
          const country1 = all.find(x => {
            let t = false
            const areas = x.impactpolicy.taxonomy.find(y => y.id === area.id)
            if (areas) {
              t = true
            }
            return x.impactpolicy_id === policy.id && x.country === c1 && t
          })
          const country2 = all.find(x => {
            let t = false
            const areas = x.impactpolicy.taxonomy.find(y => y.id === area.id)
            if (areas) {
              t = true
            }
            return x.impactpolicy_id === policy.id && x.country === c2 && t
          })
          const rowInitiatives = {
            policy: policy,
            initiatives: [country1, country2]
          }
          if (country1 || country2) {
            row.policies.push(rowInitiatives)
          }
        })
        results.push(row)
      })
      return results
    },
    initiativesByTopicAll: function () {
      const c1 = this.countrySelected1
      const c2 = this.countrySelected2
      const all = []
      const allInitiatives = this.$store.state.initiativesPolicies
      allInitiatives.forEach(x => {
        if (x.country === c1 || x.country === c2) {
          all.push(x)
        }
      })
      const results = []
      const policies = this.policies
      this.topics.forEach(topic => {
        let row = {
          topic: topic,
          policies: []
        }
        policies.forEach(policy => {
          const country1 = all.find(x => {
            let t = false
            const topics = x.impactpolicy.topics.find(y => y.name === topic)
            if (topics) {
              t = true
            }
            if (topic === 'All') {
              t = true
            }
            return x.impactpolicy_id === policy.id && x.country === c1 && t
          })
          const country2 = all.find(x => {
            let t = false
            const topics = x.impactpolicy.topics.find(y => y.name === topic)
            if (topics) {
              t = true
            }
            if (topic === 'All') {
              t = true
            }
            return x.impactpolicy_id === policy.id && x.country === c2 && t
          })
          const rowInitiatives = {
            policy: policy,
            initiatives: [country1, country2]
          }
          if (country1 || country2) {
            row.policies.push(rowInitiatives)
          }
        })
        results.push(row)
      })
      return results
    },
    policies: function () {
      const all = this.initiatives
      let results = []
      all.forEach(initiative => {
        if (initiative.impactpolicy_id) {
          results.push(initiative.impactpolicy)
        }
      })
      results = _.uniqBy(results, 'id')
      results.sort()
      return results
    },
    areas: function () {
      const all = this.initiatives
      let results = []
      all.forEach(i => {
        if (i.impactpolicy.taxonomy.length > 0) {
          i.impactpolicy.taxonomy.forEach(taxonomy => {
            results.push(taxonomy)
          })
        }
      })
      results = _.uniqBy(results, 'id')
      results.sort()
      return results
    },
    topics: function () {
      const all = this.initiatives
      let results = ['All']
      all.forEach(initiative => {
        if (initiative.impactpolicy.topics.length > 0) {
          initiative.impactpolicy.topics.forEach(topic => {
            results.push(topic.name)
          })
        }
      })
      results = _.uniq(results)
      results.sort()
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Climate Policy and Regulatory Dashboard: " + this.$route.params.country1 + ' vs ' + this.$route.params.country2
    this.$stat.log({ page: 'impactpolicies list', action: 'open impactpolicies list' })
    if (this.$route.params.country1) {
      this.countrySelected1 = this.$route.params.country1
      window.localStorage.setItem('frmCloudCountrySelected1', this.countrySelected1)
    } else if (window.localStorage.getItem('frmCloudCountrySelected1')) {
      this.countrySelected1 = window.localStorage.getItem('frmCloudCountrySelected1')
    } else {
      this.countrySelected1 = this.countryOptions1[0]
    }
    if (this.$route.params.country2) {
      this.countrySelected2 = this.$route.params.country2
      window.localStorage.setItem('frmCloudCountrySelected2', this.countrySelected2)
    } else if (window.localStorage.getItem('frmCloudCountrySelected2')) {
      this.countrySelected2 = window.localStorage.getItem('frmCloudCountrySelected2')
    } else {
      this.countrySelected2 = this.countryOptions2[0]
    }
    if (this.countrySelected1 === null || this.countrySelected1 === 'null') {
      this.countrySelected1 = this.countryOptions1[0]
    }
    if (this.countrySelected2 === null || this.countrySelected2 === 'null') {
      this.countrySelected2 = this.countryOptions2[0]
    }
    console.log('countrySelected1', this.countrySelected1)
    console.log('countrySelected2', this.countrySelected2)
    this.reloading = false
  },
  data () {
    const data = {
      reloading: true,
      countrySelected1: null,
      countrySelected2: null
    }
    return data
  },
  methods: {
    load: async function () {
    }
  },
  watch: {
    '$route.params.country1': function () {
      this.countrySelected1 = this.$route.params.country1
      this.countrySelected2 = this.$route.params.country2
      document.title = "Climate Policy Dashboard: " + this.$route.params.country1 + ' vs ' + this.$route.params.country2
      this.reloading = false
    },
    '$route.params.country2': function () {
      this.countrySelected1 = this.$route.params.country1
      this.countrySelected2 = this.$route.params.country2
      document.title = "Climate Policy Dashboard: " + this.$route.params.country1 + ' vs ' + this.$route.params.country2
      this.reloading = false
    },
    'countrySelected1': function () {
      window.localStorage.setItem('frmCloudCountrySelected1', this.countrySelected1)
      if (this.countrySelected1 !== this.$route.params.country1) {
        this.reloading = true
        this.$router.push({ name: 'ImpactPoliciesCountryComparison', params: { country1: this.countrySelected1, country2: this.countrySelected2 } })
      }
    },
    'countrySelected2': function () {
      window.localStorage.setItem('frmCloudCountrySelected2', this.countrySelected2)
      if (this.countrySelected2 !== this.$route.params.country2) {
        this.reloading = true
        this.$router.push({ name: 'ImpactPoliciesCountryComparison', params: { country1: this.countrySelected1, country2: this.countrySelected2 } })
      }
    },
    'initiativesByTopicAll': async function () {
      this.reloading = true
      await this.$nextTick()
      this.reloading = false
    }
  }
}
</script>

<style>
</style>
